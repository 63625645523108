<template>
  <ui-component-modal
    modalTitle="Lock location"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :disableSaveButton="selectedLockType === 0"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div>
        Locking the location, will disable access of all employees to the
        location management environment.
      </div>
      <div class="select">
        <select
          v-if="lockTypes && lockTypes.length > 0"
          v-model="selectedLockType"
        >
          <option :value="0" :selected="selectedLockType === 0">
            No reason selected
          </option>
          <option
            v-for="lockType in lockTypes"
            :key="lockType.Id"
            :value="lockType.Id"
          >
            {{ lockType.Reason }}
          </option>
        </select>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      lockTypes: [],
      selectedLockType: 0,
    }
  },

  computed: {
    ...mapState('locationStore', ['location', 'locationState']),
  },

  created() {
    this.lockTypes.push({ Id: 1, Reason: 'No license agreement' })
    this.lockTypes.push({ Id: 2, Reason: 'Unpaid invoices' })
    this.lockTypes.push({ Id: 3, Reason: 'Suspecious behaviour' })
    this.lockTypes.push({ Id: 4, Reason: 'Other' })
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation', 'setLocationState']),

    save() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        locationProvider.methods
          .lockLocation(self.location.Id, self.selectedLockType)
          .then((response) => {
            if (response.status === 200) {
              self.setLocationState(response.data)
              self.onClickCancel()
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
